*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
}

img:-moz-loading {
  visibility: hidden;
}

li {
  list-style: none;
}

:root {
  font-size: 62.5%;
}

@font-face {
  font-weight: 400;
  font-family: Nunito;
  font-style: normal;
  src: url('@assets/fonts/Nunito-Regular.woff2') format('woff2'),
    url('@assets/fonts/Nunito-Regular.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-weight: 600;
  font-family: Nunito;
  font-style: normal;
  src: url('@assets/fonts/Nunito-SemiBold.woff2') format('woff2'),
    url('@assets/fonts/Nunito-SemiBold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-weight: 700;
  font-family: Nunito;
  font-style: normal;
  src: url('@assets/fonts/Nunito-Bold.woff2') format('woff2'),
    url('@assets/fonts/Nunito-Bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-weight: 800;
  font-family: Nunito;
  font-style: normal;
  src: url('@assets/fonts/Nunito-ExtraBold.woff2') format('woff2'),
    url('@assets/fonts/Nunito-ExtraBold.woff') format('woff');
  font-display: swap;
}

body {
  position: relative;
  min-width: 1280px;
  color: #ffffff;
  font-weight: 400;
  font-size: 1rem;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Nunito;
  background: #000000;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes marquee {
  0% {
    left: 0%;
    transform: translateX(0%);
  }

  80%,
  100% {
    left: 100%;
    transform: translateX(calc(-100% - 8rem));
  }
}

@media (max-width: 1279px) {
  body {
    font-size: 0.7rem;
  }
}

@media (min-width: 80em) {
  body {
    font-size: 0.8rem;
  }
}

@media (min-width: 120em) {
  body {
    font-size: 1rem;
  }
}

@media (min-width: 160em) {
  body {
    font-size: 1.2rem;
  }
}

@media (min-width: 240em) {
  body {
    font-size: 2rem;
  }
}
